import React from 'react';
import { Link } from 'gatsby';

const Testimonials = props => {
    return (
        <section className="testimonials">
            <h1>Preguntas frecuentes </h1>
            <div className="testimonial-group">
                <div className="testimonial-card-1">
                    <div className="testimonial-1-message">
                        <h3 className="testimonial-2-name">¿Aceptan envases de otra marca?</h3>
                        <blockquote className="testimonial-1-content">
                            Sí. El envase no debe estar partido, no debe tener hundiduras ni tener
                            malos olores internos (combustibles y otros olores fuertes)
                        </blockquote>
                    </div>
                </div>
                <div className="testimonial-card-2">
                    <div className="testimonial-2-message">
                        <h3 className="testimonial-2-name">No tengo envase ¿Ustedes lo venden?</h3>
                        <blockquote className="testimonial-2-content">
                            Sí. El botellón nuevo incluye el agua y el domicilio. Cada vez que
                            quieras recargar el botellón con nosotros, te llevaremos un envase con
                            las mismas condiciones.
                        </blockquote>
                    </div>
                </div>
                <div className="testimonial-card-3">
                    <div className="testimonial-3-message">
                        <h3 className="testimonial-3-name">¿De dónde proviene el agua?</h3>
                        <blockquote className="testimonial-3-content">
                            Agua potable purificada bajo procesos de microfiltración,
                            ultrafiltración, rayos UV y ozono. Los botellones son lavados y
                            desinfectados previo a su llenado.
                        </blockquote>
                    </div>
                </div>
            </div>
            <div className="testimonial-paragraph">
                <h3>
                    <Link
                        to="/preguntas-frecuentes"
                        style={{
                            color: 'white',
                            textDecoration: 'underline',
                        }}
                    >
                        Ver todas las preguntas frecuentes
                    </Link>
                </h3>
            </div>
        </section>
    );
};

export default Testimonials;
