import React from 'react';

const SocialProof = props => {
    return (
        <section className="social-proof">
            <div className="social-proof-icons">
                <h4>
                    Nuestra labor es similar a un delivery de comida con la diferencia que usted
                    recibe el botellón y nos indica donde quiere que lo pongamos. No tiene que
                    preocuparse por cargar el peso del botellón.
                </h4>
            </div>
        </section>
    );
};

export default SocialProof;
